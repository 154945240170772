.container {
    color: #000;
    width: 100vw;
    height: 100%;
    position: fixed;
    z-index: 1000;

    background-color: rgb(243, 243, 243, 0);
    margin: 0 auto;
    pointer-events: none;   
    user-select: none;
}

@media screen and (min-width: 1281px) {
    .container {
        visibility: hidden;
    }
}

.innerContainer {
    opacity: 0;
}

.head {
    color: #fff;
    display: flex;    
    position: absolute;
    width: 100%;
    justify-content: space-between;

    padding-left: var(--section-padding);
    padding-right: var(--section-padding);
    margin: 0 auto;

    left: 0;
    right: 0;

    top: 50px;
    text-transform: uppercase;

    align-items: center;
    filter: invert(0);
}

@media screen and (min-width: 1281px) {

    .head {
        padding-left: var(--menu-padding);
        padding-right: var(--menu-padding);    
        max-width: initial;

    }
}

.head > h1 {
    font-size: 17px;
    font-weight: 500;
    overflow: hidden;
}

.head > div {
    font-size: 17px;
    font-weight: 300;

    position: relative;
    cursor: pointer;
    user-select: none;

    pointer-events: initial;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    left: 6px;
}

.head > div  > span:nth-child(1) {
    width: 64px;
    text-align: center;
}

.head > div  > span:nth-child(2) {
    display: block;
    position: absolute;
    width: 80px;
    height: 31px;
    border-radius: 20px;
    border: solid;
    border-width: 1.5px;
    background: linear-gradient(to top, #000000 50%, rgba(255,255,255,0) 50%);
    background-size: 100% 200%;
    transition: all .1s ease-out;
    z-index: -1;
    cursor: pointer;
}

@media screen and (max-width: 640px) {
    .head > h1 {
        font-size: 14px;
    }

    .head > div {
        font-size: 14px;
    }

    .head > div  > span:nth-child(2) {
        width: 67px;
        height: 28px;    
    }

}

.panel {
    color: "#000";
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: left;

    padding-left: var(--section-padding);
    padding-right: var(--section-padding);
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;

    justify-content: center;
    flex-direction: column;
    
}

.panel > ul {
    font-size: 100px;
    font-weight: 500;
    text-transform: uppercase;
    color: "#000";

    cursor: pointer;
}

@media screen and (max-width: 1023px) {

    .panel > ul {
        font-size: 11.5vw;
    }
}

.panel > ul > li {
    line-height: 1.2em;
    overflow: hidden;
    perspective: 40vw;
    pointer-events: initial;
    overflow: visible;

}

.panel > ul > li:nth-child(2){
    position: relative;
    left: 0.8em;
}

.copyrights {
    display: block;
    position: relative;
    font-size: 17px;
    font-weight: 300;
    left: 156px;
    top: 5vh;
    overflow: hidden;

}

@media screen and (max-width: 1023px) {

    .copyrights {
    left: 15vw;
    }

}

@media screen and (max-width: 640px) {

    .copyrights {
        left: 0;
    }
}


.foot {    
    font-weight: 300;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
    font-size: 17px;

    padding-left: var(--section-padding);
    padding-right: var(--section-padding);
    margin: 0 auto;

    bottom: 50px;
    left: 0;
    right: 0;
}

.foot > ul {
    display: flex;
    position: relative;
    pointer-events: none;
    width: 100%;
       
}

.foot > ul > li:nth-child(1)  {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    left: 15px;
    
}

.foot > ul > li:nth-child(2) {
    overflow: hidden;
}

.foot > ul > li:nth-child(3) {
    overflow: hidden;
}

.foot > ul > li:nth-child(1) > a {
    position: absolute;
}

.foot > ul > li:nth-child(1) > span {
    display: block;
    position: absolute;
    width: 53px;
    height: 31px;
    border-radius: 20px;
    border: solid;
    border-width: 1.5px;
    background-color: #000;
    z-index: -1;
    border: none;

}

.foot > ul > li:nth-child(2) {
    margin-left: 156px;
    margin-right: 282px;
}

@media screen and (max-width: 1023px) {

    .foot > ul > li:nth-child(2) {
        margin-left: 15vw;
        margin-right: initial;
    }

    .foot > ul > li:nth-child(3) {
        margin-left: auto;
        margin-right: 0;
    }

}

@media screen and (max-width: 640px) {

    .foot {
        font-size: 14px;
    }

    .foot > ul > li:nth-child(1) > span {
        height: 28px;
    }

}

@media screen and (max-width: 1023px) {

    @media screen and (orientation:landscape) {
        
        .panel > ul {
            font-size: 11.5vh;
        }
        
    }

}

@media screen and (min-width: 1281px) {

    .head > h1 {
        font-size: 14px;
    }    

    .head > div {
        font-size: 14px;
    }    

    .head > div  > span:nth-child(2) {
        width: 70px;

    }

    .panel {
        padding-left: var(--menu-padding);
        padding-right: var(--menu-padding);
    }

    .foot {
        padding-left: var(--menu-padding);
        padding-right: var(--menu-padding);
    }
}