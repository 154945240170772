.canvasServices {
    position: relative;
    margin: auto;
    width: var(--services-image-width) !important;
    height: var(--services-image-height) !important;
}

.canvasSystem {
    position: relative;
    margin: auto;

    width: var(--system-image-width) !important;
    height: var(--system-image-height) !important;
}

.canvasCursor {
    position: relative;
    margin: auto;

    width: 442px !important;
    height: 249px !important;
    
}

.canvasSystemInfo {
    position: relative;
    margin: auto;

    width: 303px !important;
    height: 193px !important;
    
}