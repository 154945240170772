.container {
    z-index: 3;
    position: fixed;
    max-width: var(--menu-width);
    padding-top: 60px;
    margin: 0 0;
    pointer-events: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    left: 0px;
    right: 0px;
    user-select: none;
    pointer-events: none;
}

@media screen and (max-width: 1280px) {
    .container {
        visibility: hidden;
    }
}

@media screen and (min-width: 1281px) {
    .container {
        padding-left: var(--menu-padding);
        padding-right: var(--menu-padding);
    }
}


.container > h1 {
    display: initial;
    font-size: var(--menu-title);
    font-weight: 400;
    pointer-events: auto;
    cursor: pointer;
}

.container > ul{
    display: inline-flex;
    justify-content: space-between;
    float: right;
    font-size: var(--menu-list);
    margin-right: 0;
    font-weight: 300;
    text-transform: uppercase;
    align-items: center;
    width: 50%;
}

.container > ul > li{
    pointer-events: all;   
    position: relative;
    cursor: pointer;
}

.container > ul > li:nth-child(5){
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #FFF;
}

.lang {
    display: block;
    position: absolute;
    width: 43px;
    height: 26px;
    border-radius: 20px;
    border: solid;
    border-width: 1px;
    background: linear-gradient(to top, #ffffff 50%, rgba(255,255,255,0) 50%);
    background-size: 100% 200%;
    transition: all .1s ease-out;
    border-color: #fff;
}

.lang > div > div {
    z-index: 1;
}
