.container {
    width: 70px;
    height: 70px;
    position: relative;
    transform-origin:center center;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 52px;
    height: auto;
    position: absolute;
    pointer-events: none;
}

@media (hover: none) {
    .container{
        pointer-events: none;
    }   
}

@media screen and (max-width: 640px) {
    .container{
        transform: scale(0.7);
        width: 56px;
        height: 56px;
    }
}