.container {
    position: fixed;
    z-index: 10;
    bottom: 45px;
    left: 10px;
    margin: 0 auto;
    left: 0;
    right: auto;
    padding-left: var(--menu-padding);
}

@media screen and (max-width: 1280px) {

    .container{
        padding-left: var(--section-padding);
        left: 0;
        right: auto;
        bottom: 47px;
    }
}

.arrowContainer{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(var(--icon-back-scale));
}

.arrowContainer > div {
    position: absolute;
}

/* to bottom */
.arrowContainer > div:nth-child(2) {
    transform: rotateZ(0deg);
}

/* to top */
.arrowContainer > div:nth-child(3) {
    transform: rotateZ(180deg);
    display: none;
}
