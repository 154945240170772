.mouseFollower{
    width: 15px;
    height: 15px;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    background-color: #FFF;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
}

@media (hover: none) {

    .mouseFollower{
        visibility: hidden;
    }


}