/* common */
.text {
    font-family: "Hiragino Sans", Meiryo, sans-serif;
    font-size: var(--text-size);
    font-weight: 300;
    line-height: 1.2em;
    line-break: strict;

  }

/* About */
.sectionAbout > p {
  font-family: "Hiragino Sans", Meiryo, sans-serif;
  font-size: 60px;
  line-height: 1.17em;
  font-weight: var(--about-text-ja-weight);
}

.textAbout {
  font-weight: var(--about-text-ja-weight);
}

/* Design */

.textDesignMain {
  font-family: "Hiragino Sans", Meiryo, sans-serif;
  font-size: var(--services-text-ja-main-size);
  font-weight: var(--services-text-ja-main-weight);
  line-height: 1.25em;
  line-break:auto;
}

.textDesignSub {
  font-family: "Hiragino Sans", Meiryo, sans-serif;
  font-size: var(--services-text-ja-main-sub);
  font-weight: 300;
  line-height: 1.2em;
  line-break:auto;

}

/* Vision */

.textVision {
  font-family: "Hiragino Sans", Meiryo, sans-serif;
  font-size: var(--vision-text-ja);
  font-weight: var(--vision-text-ja-weight);
  line-height: 1.35em;
}

/* Partners */

.textTeams {
    font-family: "Hiragino Sans", Meiryo, sans-serif;
    font-size: var(--teams-text-ja-size);
    font-weight: 300;
    line-height: 1.2em;
    
  }

/* System */

.collapsibleOpen2 {
  width: 500px !important;
  /*width: 600px;*/
}

.collapsibleOpen3 {
  width: 600px !important;
  /*width: 600px;*/
}
