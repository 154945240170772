@media screen and (max-width: 1023px) {
    .icon {
        width: 10vw;
        height: 10vw;
    }
}

@media screen and (max-width: 640px) {
    .icon {
        stroke-width: 2px;
    }
}