.splash {
  width: 50px;
  margin-left: 10px;
}

.splashText {
  font-weight: 500;
  font-size: 30px;
  display: block;
  overflow: hidden;
  position: relative;
  bottom: -8px;
  margin-left: 20px;
}

/* Common */
.tag {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.tag ::first-letter {
  padding-left: 15px;
}

.text {
  font-size: var(--text-size);
  font-weight: 300;
  line-height: 1.3em;
  line-break: strict;
}

/* Text */

.titleTop {
  font-size: var(--top-title-size);
  transform-origin:  50% 50%,
  
}

.titleDesign {
  font-size: var(--design-title-size);
  font-weight: 500;
  line-height: 1.125em;
}

.titleSystem {
  font-size: var(--system-title);
  font-weight: 500;
}

.titleSystemInfo {
  font-size: var(--system-title-info);
  font-weight: 300;
}

.textSystemInfo {
  font-size: var(--system-info-text);
  font-weight: 300;
  line-height: 1.3em;
  line-break: strict;

}

.titleShowcase {
  font-size: var(--showcase-title);
  font-weight: 500;
}

.titlePartners {
  font-size: var(--title-teams);
  font-weight: 500;
}

.subTitlePartners {
  font-size: var(--teams-logo-size);
  font-weight: 500;
}

.subTitleShowcase {
  font-size: 20px;
}

.subTitle2Showcase {
  font-size: var(--showcase-title-sub);
  font-weight: 500;
  line-height: 1.125em;
}

.titleContact > span {
  font-size: var(--contact-title);
}

.titleContact > div > span {
  font-size: var(--contact-title-sub);
}

.textAbout {
  font-size: var(--about-text-size);
  line-height: 1.35em;
  font-weight: 500;
}

.textDesignMain {
  font-size: var(--services-text-main-size);
  font-weight: 500;
  line-height: 1.125em;
}

.textDesignSub {
  font-size: var(--services-text-main-sub);
  font-weight: 300;
  line-height: 1.3em;
}

.textVision {
  font-size: var(--vision-text);
  font-weight: 500;
  line-height: var(--vision-text-height);
}

.textTeams {
  font-size: var(--teams-text-size);
  font-weight: 300;
  line-height: var(--teams-text-line);
}

.textContact {
  font-size: var(--contact-text-size);
  font-weight: 300;
}

.textFooter {
  font-size: var(--contact-footer-text-size);
  font-weight: 300;
}

/* Top */
.sectionTitle {
  max-width: var(--section-width);
  margin: 0 auto;
  align-items: "center";
  display: "flex";
  flex-wrap: "wrap";
  align-content: center;
  position: relative;


  height: 100vh;
  max-height: -webkit-fill-available;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.sectionTitle p {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5em;
  position: absolute;
  left: var(--top-text-left);
  padding-top: 30px;
}

@media screen and (max-width: 1280px) {
  .sectionTitle p {
    position: relative;
    padding-top: 0;
    bottom: 10vh; 
  }
}

@media screen and (max-width: 1023px) {
  .sectionTitle p {
    font-size: clamp(12px,2vw,14px);
  }

  @media screen and (orientation:landscape) {
    .sectionTitle p {
      font-size: clamp(12px,2vh,14px);
    }
  }  
}

.containerTitle {
  width: 100%;
  position: relative;
}

.containerTitle > h1 {
  position: relative;
  width: fit-content;
  left: var(--top-title-left);
}

.containerTitle > h1 > span {
  display: block;
}


.titleTop > span:nth-child(1) {
  text-align: left;
}

.titleTop > div {
  display: flex;
  overflow: hidden;
}

.titleStroke {
  display: block;
  color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

@media screen and (max-width: 1023px) {

  .titleTop > div {
    display: flex;
    flex-direction: column;
  }
  
}

.titleTop > div > span:nth-child(1) {
  padding-right: var(--top-text-space);
}

.scrollAttention {
  position: relative;
  top: -73px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-weight: 300;
}

/* container */

.container {
  width: 100%;
}

.background {
  width: 100vw;
  background-color: rgba(21, 21, 21, 0);
}

/* About */

.sectionAbout {
  padding-top: 470px;
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
  margin: 0 auto;
  max-width: var(--section-width);
}

.sectionAbout > p {
  left: var(--about-text-left);
  position: relative;
}

@media screen and (max-width: 640px) {
  .sectionAbout {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    height: 100vh;
  }
}

/* Design */

.sectionDesign {
  max-width: var(--section-width);
  margin: 0 auto;
  margin-top: var(--section-padding-top);
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
}

@media screen and (max-width: 640px) {

  .sectionDesign{
    margin-top: 0;
  }
  
}

.containerDesign > .containerImage {
  margin-top: var(--services-image-margin-top);
}

.containerDesign > .containerImageMobile {
  margin-top: -45px !important;
  width: 686px;
  margin: 0 auto;
}


.containerDesign h2 {
  position: relative;
  z-index: 1;
  width: fit-content;
  height: fit-content;
}

.textDesign {
  margin-top: 30px;
}


/* Multiple Envrionments */

.sectionDesign > div:nth-child(2) {
  padding-top: 60px;
}

@media screen and (max-width: 1280px) {
  .textDesign br {
    display: none;
  }
}


.textDesign > :nth-child(1) {
  position: relative;
  right: 0px;
  width: fit-content;

  left: var(--services-environments-text-padding);
}

.textDesign > :nth-child(2) {
  position: relative;
  right: 0px;
  padding-top: 25px;
  width: fit-content;

  left: var(--services-environments-text-padding);

}

/* Interactive Design */

.sectionDesign > div:nth-child(3) {
  padding-top: 300px;
}

.sectionDesign > div:nth-child(3) > div:nth-child(3) > :nth-child(1) {
  left: var(--services-interactive-text-padding);
}

.sectionDesign > div:nth-child(3) > div:nth-child(3) > :nth-child(2) {
  left: var(--services-interactive-text-padding);
}

/* Remote Control */

.sectionDesign > div:nth-child(4) {
  padding-top: 270px;
}

.sectionDesign > div:nth-child(4) > div:nth-child(3) > :nth-child(1) {
  left: var(--services-remote-text-padding);
}

.sectionDesign > div:nth-child(4) > div:nth-child(3) > :nth-child(2) {
  left: var(--services-remote-text-padding);
}




@media screen and (max-width: 1280px) {
  .textDesign > :nth-child(1) {
    width: 608px;
    margin: 0 auto;
  }

  .textDesign > :nth-child(2) {
    width: 608px;
    margin: 0 auto;

  }

}

@media screen and (max-width: 1023px) {

  .textDesign > :nth-child(1) {
    width: 100%;
  }

  .textDesign > :nth-child(2) {
    width: 100%;
  }

  .sectionDesign > div:nth-child(3) > div:nth-child(3) > :nth-child(1) {
    width: 100%;
  }
  
  .sectionDesign > div:nth-child(3) > div:nth-child(3) > :nth-child(2) {
    width: 100%;
  }

  .sectionDesign > div:nth-child(4) > div:nth-child(3) > :nth-child(1) {
    width: 100%;
  }
  
  .sectionDesign > div:nth-child(4) > div:nth-child(3) > :nth-child(2) {
    width: 100%;
  }
  
}

/* System */

.sectionSystem {
  max-width: var(--section-width);
  margin: 0 auto;
  padding-top: var(--section-padding-top);
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
}

.sectionSystem h2 {
  margin-top: -120px;
  text-align: left;
  width: fit-content;
  height: fit-content;
}

@media screen and (max-width: 1280px) {

  .sectionSystem h2 {
    margin-top: -100px;
  }

}

@media screen and (max-width: 1023px) {

  .sectionSystem h2 {
    margin-top: -0.9em;
  }

}

.sectionSystem p {
  padding-top: 17px;
  width: var(--system-text-width);
}

@media screen and (max-width: 1023px) {
  .sectionSystem br {
    display: none;
  }
}

.systemInfoTop {
  margin-top: 50px !important;
}

.containerSystem > .containerImage {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 47px;
  margin: 0 auto;
}

.containerSystem > .containerImageMobile {
  width: 764px;
  margin: 0 auto;
}


.containerSystemInfo {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.containerSystemInfo > div {
  width: 100%;
  position: relative;
  z-index: 5;
}

.border {
  width: 0%;
  height: 1px;
  background: #fff;
}

.systemInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--system-info-title-height);
}

.collapsibleOpen p {
  width: 486px;
  padding-bottom: 96px;
}

.containerSystem > .containerSystemInfo > div > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.systemInfoInner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1280px) {
  .collapsibleOpen p {
    width: 606px;
  }

}

@media screen and (max-width: 1023px) {
}

.systemInfoInner > .containerImage {
  bottom: 30px;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .systemInfoInner > .containerImage {
    margin-left: auto;
  }

  .systemInfoInner {
    flex-direction: column;
  }

  .collapsibleOpen p {
    width: initial;
  }
  
}

/* Showcase */

.sectionShowcase {
  max-width: var(--section-width);
  margin: 0 auto;
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);

  padding-top: var(--section-padding-top);
}

.showcaseA {
  padding-top: 137px;
}

.showcaseB {
  padding-top: 216px;
}

.showcaseC {
  padding-top: 216px;
}

.sectionShowcase > h2 {
  width: fit-content;
}

.showcaseVideoContainer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.videoPlayer{
  position: relative;
  width: 100vw !important;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: calc(100vw * 0.425) !important;
    
}

.sectionShowcase > div > .containerImage {
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 27px;
}

.sectionShowcase h3 {
  margin-top: 20px;
  position: relative;
  z-index: 1;
  text-transform: initial;
  text-transform: uppercase;
}

.sectionShowcase p {
  padding-top: 20px;
  width: var(--showcase-text-width);
}

.showcaseLink {
  pointer-events: all;
  cursor: pointer;
  text-decoration: underline;
  display: block;
}

/* Vision */

.sectionVision {
  max-width: var(--section-width);
  margin: 0 auto;
  margin-top: var(--section-padding-top);
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
}

.sectionVision p {
  padding-top: 70px;
}

/* Partners */

.borderPartners {
  width: 0%;
  height: 1px;
  background: #fff;
}

.sectionPartners {
  padding-bottom: var(--teams-padding-bottom);
  max-width: var(--section-width);
  margin: 0 auto;
  margin-top: var(--section-padding-top);
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
}

.sectionPartners h2 {
  width: fit-content;
  height: fit-content;
}

.sectionPartners > p {
  padding-top: 24px;
  padding-bottom: 100px;
  width: var(--teams-text-width);
}

.containerPartners > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerPartners > div {
  height: 225px;
}

.containerPartners {
  font-weight: 300;
}

.containerPartners > div > div:nth-child(1) {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.containerPartners > div > div:nth-child(2) {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  .containerPartners > div {
    display: initial;
  }

  .containerPartners {    
    height: 373px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .containerPartners > div > div:nth-child(1) {
      padding-bottom: 30px;
  }

}

.containerPartners > div > div > h3 {
  padding-bottom: 30px;
  text-transform: initial;
}

.containerPartners > div > div > span:nth-child(3) {
  padding-top: 1rem;
}

.ac {
  font-size: 52px !important;
}

.containerPartners > div > div > p {
  width: 485px;
  height: 80%;
}

.url {
  text-decoration: underline;
  width: fit-content;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1023px) {
  .containerPartners > div > div:nth-child(2) {
    width: initial;
  }
  
  .containerPartners > div > div > p {
    width: initial;
    height: initial;
  }

  .containerPartners {    
    height: initial;
    display: initial;
    padding: 48px 0;
    display: inline-table;
  }

  .containerPartners > div > div:nth-child(1) {
    width: initial;
  }
}

/* Contact */

.sectionContact {
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  filter: "invert(1)";
  max-width: var(--section-width);
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
  margin: 0 auto;
  width: 100%;
  color: #000;
}

.containerContact {
  position: relative;
  left: var(--contact-text-left);
}

.containerContact h2 {
  display: flex;
  flex-direction: column;
  text-transform: none;
  color: rgba(0, 0, 0, 0);
  width: fit-content;
  height: fit-content;
  pointer-events: all;
}

.containerContact h2 > div {
  display: flex;
  align-items: center;
}

.containerContact h2 > span {
  color: rgb(0, 0, 0);
}

.containerContact h2 > div > :nth-child(2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {

  .containerContact h2 > div > :nth-child(2) {
    margin-left: 10px;
  }    

}

.containerContact h2 > :nth-child(2) > span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.containerContact > p {
  line-height: 1.4em;
  position: relative;
  bottom: 8vh;
  text-transform: uppercase;
}

.contactMobileCopyright {
  font-size: var(--contact-footer-text-size);
}

@media screen and (max-width: 640px) {

  .containerContact > p {
    width: 90%;
  }

  .containerContact br {
    display: none;
  }

}

/* footer */

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  max-width: var(--menu-width);
  align-items: flex-end;
  width: 100%;
  padding-bottom: 50px;
  padding-left: var(--menu-padding);
  padding-right: var(--menu-padding);
  margin: 0 auto;
  color: #000;
}

.footer > div:nth-child(1) {
  position: relative;
}

.line {
  display: block;
  position: relative;
  bottom: -5px;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: #000;
}

@media screen and (max-width: 1280px) {

  .footer > div {
    width: 50%;
  }
  
  .footer > div:nth-child(1) {
    margin-left: var(--contact-text-left);
  }

}

@media screen and (max-width: 1023px) {

  .footer{
    max-width: var(--section-width);
    padding-left: var(--section-padding);
    padding-right: var(--section-padding);
  }
  
  .footer > div {
    width: unset;
  }

}

.invert {
  color: #fff;
}

